import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {login, selectUser} from "../redux/userSlice";
import NavbarHomePage from "./NavbarHomePage";
import {
    Alert,
    AlertTitle,
    CircularProgress,
    FormControl, InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    ThemeProvider
} from "@mui/material";
import {useLocation, useNavigate} from "react-router";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const Login = (props) => {

    const navigation = useNavigate();
    const user = useSelector(selectUser);

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const redirect = query.get('redirect');
    const jwtToken = query.get('jwt');

    useEffect(() => {
        if (jwtToken) {
            dispatch(login({
                username: "",
                password: "",
                token: jwtToken
            }));

            window.localStorage.setItem('token', jwtToken);

            window.location.href = `/${redirect}`;
        }
    }, [jwtToken]);

    const defaultEmail = query.get('email');

    useEffect(() => {
        setValues({...values, email: defaultEmail, confirmEmail: defaultEmail});
    }, []);

    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
    });

    const [errors, setErrors] = useState({
        email: false,
        password: false,
    });

    const [errorMessages, setErrorMessages] = useState({
        email: '',
        password: '',
    });

    const [loginError, setLoginError] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const handleValues = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        inputValidation(event, prop);
    };

    const inputValidation = (e, prop) => {
        let value = e.target.value;
        let error = false;
        let errorMessage = '';

        switch (prop) {
            case 'email':
                if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                    error = true;
                    errorMessage = 'El email no es válido';
                }
                break;
        }
        if (!value) {
            error = false;
            errorMessage = '';
        }

        setErrors({...errors, [prop]: error});
        setErrorMessages({...errorMessages, [prop]: errorMessage});
    }

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const login_api = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            var details = JSON.stringify({
                "username": values.email,
                "password": values.password,
                "login_user": true
            });

            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/authentication`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'same-origin',  // Add this line to handle cookies
                    body: details,
                }
            );

            if (response.ok) {
                const auth = await response.json();

                setLoginError(false);

                dispatch(login({
                    username: "",
                    password: "",
                    token: auth.auth
                }));

                window.localStorage.setItem('token', auth.auth);

                navigation('/index', {replace: true});
            }
            else {
                setLoginError(true);
                setValues({...values, password: ''});
            }
            setLoading(false);

        } catch (error) {
            setLoading(false);
            setLoginError(true);
        }
    };

    useEffect(() => {
        if (user.token) {
            navigation(`/${redirect}`, {replace: true});
        }
    }, [user.token]);

    return (
        <div style={{backgroundColor: "#1a1a1a", color: "whitesmoke", marginTop: "60px"}}>
            <ThemeProvider theme={props.theme}>
                <NavbarHomePage />
                {loading && (
                    <div className="text-center" style={{padding: "20rem 0"}}>
                        <CircularProgress color="secondary" size={100} />
                    </div>
                )
                }
                {!loading && (
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 offset-md-1 mt-3">
                            <form onSubmit={login_api}>
                                {loginError && (
                                    <div className="mb-3">
                                        <Alert variant="filled" severity="error">
                                            El email o la contraseña son incorrectos
                                        </Alert>
                                    </div>
                                )}
                                <div className="form-group mb-3">
                                    <TextField type="email" id="email" label="Email" fullWidth variant="outlined" onChange={handleValues('email')} value={values.email} autoComplete="email" required error={errors.email} helperText={errorMessages.email} />
                                </div>
                                <div className="form-group mb-3">
                                    <FormControl fullWidth variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={values.showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={handleValues('password')}
                                            required
                                            error={errors.password}
                                            helperText={errorMessages.password}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-group mb-3">
                                    <button type="submit" className="btn w-100 btn-primary">Iniciar Sesión</button>
                                </div>
                            </form>
                            <div className="row">
                                <div className="col-12">
                                    <Link to={"/register"}>Creá tu cuenta</Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <Link to={"/forgot-password"}>Olvidé mi contraseña</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </ThemeProvider>
        </div>
    );


};





export default Login;
import React from 'react';
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div style={{backgroundColor: "#1a1a1a"}}>
            <footer className="pt-5 pb-0" style={{backgroundColor: "rgba(0,0,0,0.2)", color: "whitesmoke"}}>
                <div className="container">
                    <div className="row justify-content-center text-center">
                        <div className="col-md-3 d-none flex-column justify-content-center text-center">
                            <div>
                                <img className="img-fluid" width="50%" src={"../assets/images/download-on-the-apple-store.png"} alt="logo" />
                            </div>
                            <div className="mt-3">
                                <img className="img-fluid" width="50%" src={"../assets/images/get-it-on-play-store.svg"} alt="logo" />
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-0">
                            <h6 className="text-uppercase fw-bold mb-4">
                                FlashPass
                            </h6>
                            <p>
                                <a href="../" className="text-reset">Inicio</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Organizar</a>
                            </p>
                            <p>
                                <a href="https://flashpass.com.ar/crear_evento" className="text-reset">Crear Evento</a>
                            </p>
                            <p>
                                <Link to="/faq" className="text-reset">Ayuda</Link>
                            </p>
                        </div>
                        <div className="col-md-3 mt-5 mt-md-0">
                            <h6 className="text-uppercase fw-bold mb-4">
                                Legal
                            </h6>
                            <p>
                                <a href="https://flashpass.com.ar/terminos-y-condiciones" className="text-reset">Términos y Condiciones</a>
                            </p>
                            <p>
                                <a href="https://flashpass.com.ar/privacy" className="text-reset">Política de Privacidad</a>
                            </p>
                            <p>
                                <a href="#!" className="text-reset">Política de Devolución</a>
                            </p>
                            <p>
                                <a target="_blank"
                                   href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/0-4999/638/texact.htm"
                                   className="text-reset">Ley de defensa del consumidor</a>
                            </p>
                        </div>
                        <div className="col-md-3 mt-5 mt-md-0">
                            <h6 className="text-uppercase fw-bold mb-4">Contacto</h6>
                            <p>
                                Flashpass S.R.L.
                            </p>
                            <p>
                                CUIT 30-71754335-8
                            </p>
                            <p>
                                info@flashpass.com.ar
                            </p>

                            <p>
                                <a href="http://qr.afip.gob.ar/?qr=abKUiuEiFFSFDaWycAzyog,," target="_F960AFIPInfo"><img
                                    src="http://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0" width="30px" /></a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="text-center p-4" style={{backgroundColor: "rgba(0, 0, 0, 0.075)"}}>
                    Todos los derechos reservados. © Copyright 2023 FlashPass.
                </div>
            </footer>
        </div>
    );
};

export default Footer;